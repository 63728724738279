<template>
  <div class="px-8 py-10 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full">
        <TitleComponent class="py-4" title="Technical Papers" size="large" />

        <div
          class="flex flex-wrap mt-2 mb-4 px-6 py-8"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full">
            <p class="p-light mb-4">
              <a
                class="link"
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/StatisticalRelativeComparisonMethod.pdf?alt=media&token=fd5b8bc1-c5de-4c4c-b12b-77bbf892e6ac"
                >Statistical Relative Comparison Method</a
              >
              a method to statistically compare the results of sensitivity tests
              completed using Bruceton, Neyer, SEQ or other method resulting in
              an estimate of the curve defining the reaction transition as a
              function of sensitivity test energy.
            </p>
            <p class="p-light mb-4">
              <a
                class="link"
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/ChartSignificanceMethod.pdf?alt=media&token=7b5c6c39-b20c-48d1-a7a0-745034a61691"
                >Chart Significance Method</a
              >: a method to statistically compare the results of sensitivity
              tests completed at a given energy level. Also available is
              <a
                class="link"
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/ChartSignficanceMethodStatementfromBYU.pdf?alt=media&token=0248446f-4111-4c6e-bc5b-b4109f080446"
                >a peer review of the method by a statistician</a
              >.
            </p>
            <p class="p-light mb-4">
              <a
                class="link"
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/7f-BulkThermalSBATPaper.pdf?alt=media&token=4ebe7f5d-9d80-4877-ad9d-136cc2921b78"
                >Bulk Thermal Stability Characterization via the SBAT
                Apparatus</a
              >: a comparison between the DSC, SBAT, and ARC used for thermal
              characterization of thermal stability.
            </p>
          </div>
        </div>
        <div class="w-3/4 m-auto text-left mt-8 text-md">
          <p class="leading-relaxed" style="color:#7e8890;">
            Terms of Use
            <br />
            All material on this website is copyrighted by the ET Users Group™.
            By accessing the content in the Test Methods Matrix™ you agree to
            the following:
          </p>
        </div>
        <div
          style="color:#7e8890;"
          class="w-3/4 pl-16 md:pl-32 text-left ml-10 mt-4 text-md"
        >
          <ul class="list-disc">
            <li class="mt-2">
              You will not make copies of this material or otherwise make this
              content available offline for others (such as but not limited to
              creating training materials) without written consent from the ET
              Users Group;
            </li>
            <li class="mt-2">
              You will direct inquiries regarding access to this content to the
              ET Users Group;
            </li>
            <li class="mt-2">
              You will not hold liable the ET Users Group or any associated
              entity for damages caused by the use or misuse of this content.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss"></style>
